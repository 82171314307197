<template>
  <div class="container">
    <div class="row">
      <div class="col">

        <embed :src="'https://convalida.ucsp.edu.pe/api/pdf/' + token + '/' + id" type="application/pdf" width="100%" height="600px" />

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PDF',
  data() {
    return {
      token: this.$route.params.token,
      id: this.$route.params.id,
    }
  },

  created() {
  },

  methods: {

  }
}
</script>

<style scoped>
</style>
